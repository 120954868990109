<template>
  <div class="animated fadeIn">
    <b-card  :title="$t('message.title.report')" style="top: 10px">
      <b-form @submit.prevent="searchFn">

        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
            {{ $t("message.year") }} :
            <br />
            <b-form-select v-model="timeSel" :options="language== 'th'? timeOptTH: timeOptEN" v-on:change="selectTime" />
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4 my-1">
            {{ $t("message.machineIdFarm") }} :
            <br />
            <list-select :list="machineData" :selected-item="selectedId" placeholder="Select Machine ID" option-value="machineId" option-text="name" @select="onSelect" required />
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
            {{ $t("message.selectDateTime") }} :
            <br />
            <b-form-select v-model="searchData.type" :options="$t('message.arrays.dateType')" />
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1" v-if="searchData.type === 'selectDate'">
            {{ $t("message.date") }} :
            <br />

            <div class="table-responsive">
              <date-time-picker v-model="searchData.datetime"></date-time-picker>
            </div>
          </div>

          <div class="col-sm-6 col-md-5 col-lg-2 col-xl-1 my-1 ml-1">
            <br />
            <b-btn class="col-sm-12 col-md-6 col-lg-12" variant="primary" type="submit">{{$t("message.search") }}</b-btn>
          </div>
        </div>
        <br />
      </b-form>

      <div class="chart-wrapper" v-if="chartData.series">
        <apexchart height="250" :options="options" :series="chartData.series"></apexchart>
      </div>

      <!-- <div id="chart">
        <div id="responsive-chart">
           <apexchart  height="300" :options="options" :series="chartData.series"></apexchart>
        </div>
      </div>-->

      <div class="row">
        <div class="col-12 col-sm-12 col-md-8">
          <div class="table-responsive" v-if="reportData.row">
            <table class="table-grid">
              <thead style="text-align-last: left">
                <th>{{ $t("message.type") }}</th>
                <th>{{ $t("message.productName") }}</th>
                <th>{{ $t("message.transactions") }}</th>
                <th>{{ $t("message.sale") }}</th>
              </thead>
              <tbody>
                <template v-for="(wash, washIndex) in reportData.row.wash">
                  <tr>
                    <td :rowspan="reportData.row.wash.length" v-if="washIndex === 0">{{ $t("message.wash") }}</td>
                    <td>{{ wash.product.name }}</td>
                    <td style="text-align: right">{{ Number( wash.qty ).toLocaleString() }}</td>
                    <td style="text-align: right">{{ Number( wash.price ).toLocaleString() }}</td>
                  </tr>
                </template>
                <template v-for="(dry, dryIndex) in reportData.row.dry">
                  <tr>
                    <td :rowspan="reportData.row.dry.length" v-if="dryIndex === 0">{{ $t("message.dry") }}</td>
                    <td>{{ dry.product.name }}</td>
                    <td style="text-align: right">{{ Number( dry.qty ).toLocaleString() }}</td>
                    <td style="text-align: right">{{ Number( dry.price ).toLocaleString() }}</td>
                  </tr>
                </template>
                <template v-for="(das, dasIndex) in reportData.row.das">
                  <tr>
                    <td :rowspan="reportData.row.das.length" v-if="dasIndex === 0">{{ $t("message.das") }}</td>
                    <td>{{ das.product.name }}</td>
                    <td style="text-align: right">{{ Number( das.qty ).toLocaleString() }}</td>
                    <td style="text-align: right">{{ Number( das.price ).toLocaleString() }}</td>
                  </tr>
                </template>
                <tr>
                  <td colspan="2" style="text-align: right">{{ $t("message.sum") }}</td>
                  <td style="text-align: right">{{ Number( reportData.totalQty).toLocaleString() }}</td>
                  <td style="text-align: right">{{ Number( reportData.totalAmount).toLocaleString() }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4">
          <div class="table-responsive" v-if="reportData.totalAmount">
            <table class="table-grid">
              <thead style="text-align-last: left">
                <th>{{ $t("message.sum") }}</th>
                <th></th>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t("message.sale") }}</td>
                  <td style="text-align-last: right">{{ Number(reportData.totalAmount).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>{{ $t("message.totalWash") }}</td>
                  <td style="text-align-last: right">{{ Number(reportData.totalWashAmount).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>{{ $t("message.totalDry") }}</td>
                  <td style="text-align-last: right">{{ Number(reportData.totalDryAmount).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>{{ $t("message.totalDAS") }}</td>
                  <td style="text-align-last: right">{{ Number(reportData.totalDASAmount).toLocaleString() }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import webServices from '../../script'
import { ListSelect } from '../../custom_modules/search-select'
import DateTimePicker from '../../components/DateTimePicker/Custom.vue'
import axios from 'axios'

// import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import moment from 'moment'
export default {
  name: 'Reports',
  components: {
    ListSelect,
    DateTimePicker
  },
  data() {
    return {
      api: {},
      timeSel: 0,
      timeOptTH: [
        { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
        { value: 1, text: '<2022'}
      ],
      timeOptEN: [
        { value: 0, text: '2022 To This Year' },
        { value: 1, text: '<2022'}
      ],
      language: window.localStorage.getItem("language"),
      chartData: {
        options: {
          xaxis: {
            categories: []
          },
          colors: [],
          chart: { id: 'sale-charts' }
        },
        series: []
      },
      params: {
        from: '',
        to: '',
        machineId: 7
      },
      options: {},
      machineData: [],
      selectedId: {},
      searchData: {
        // datetime: '',
        datetime: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
      },
      type: '',
      reportData: {}
    }
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },

    getMachine() {
      this.api
        .get(`/machines/list?`)
        .then(res => {
          this.machineData = res.data
        })
        .catch(err => {
          this.$toast.error({
            title: 'ERROR',
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          })
          console.log('error @machine')
          console.log(err)
        })
    },

    showAlert_ChooseOneBranch() {
      if(window.localStorage.getItem("language") == 'th'){
        var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
        var text = 'ไม่สามารถเลือกเดือนได้มากกว่า 12 เดือน'
        var confirmButton = "ปิด"
      }else{
        var title = "Please select a new time again"
        var text = "Cannot select more than 12 months."
        var confirmButton = "Close"
      }

      this.$swal({
        title: title,
        text: text,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: confirmButton
      })
    },

    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },

    async searchFn() {
      this.chartData.series = []
      if (this.searchData.type === 'selectDate') {
        this.searchData.from = moment(this.searchData.datetime[0]).format('YYYY-MM-DD HH:mm:ss')
        this.searchData.to = moment(this.searchData.datetime[1]).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.searchData.from = ''
        this.searchData.to = ''
      }

      var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest

      var dateStart = new Date(this.searchData.from)
      var dateEnd = new Date(this.searchData.to)

      console.log('searchData', this.searchData )

      // console.log("เลือก 1 สาขา หรือ dealer")
      // console.log("monthDiff", this.monthDiff(dateStart, dateEnd))

      if(this.monthDiff(dateStart, dateEnd) > 12){
        console.log("เลือกเดือนได้สูงสุด 12 เดือน")
        this.showAlert_ChooseOneBranch() // เลือกเดือนได้สูงสุด 12 เดือน
      }else{
        await this.getSaleReport()
        await this.getChartsReport()
      }
    },
    async  getChartsReport() {
      this.$Progress.start()
      this.api
        .get(`/report/chartsalereport`, { params: this.searchData })
        .then(res => {
          this.$Progress.finish()
          if (res.data.success) {
            if (this.searchData.type !== 'today') {
              res.data.charts.datasets.forEach(val => {
                if (val.label !== 'totalAmount') {
                  this.chartData.series.push({
                    type: 'bar',
                    name: val.label === 'totalWashAmount' ? 'Wash' : val.label === 'totalDryAmount' ? 'Dry' : val.label === 'totalDASAmount' ? 'Detergent & Softener' : null,
                    data: val.data
                  })
                  this.chartData.options.colors.push(val.backgroundColor)
                } else {
                  this.chartData.series.push({
                    type: 'line',
                    name: 'Total',
                    data: val.data
                  })
                  this.chartData.options.colors.push(val.backgroundColor)
                }
              })

              const label = JSON.parse(JSON.stringify(this.chartData.options))
              this.options = label
              this.options.xaxis.categories = res.data.charts.labels
            }
          } else {
            const errorText = {
              errorCode: res.data.errorCode,
              errorText: res.data.errorText ? res.data.errorText : res.data.errors
            }
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(errorText)
            })
          }
        })

        .catch(err => {
          this.$Progress.fail()
          if (err.response) {
            if (err.response.status === 401) {
              webServices.tokenExpire()
            }
          }
          this.$toast.error({
            title: 'ERROR',
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          })
          console.log('error @Get charts')
          console.log(err)
        })
    },
    async getSaleReport() {
      this.$Progress.start()
      this.api
        .get(`/report/salereport`, { params: this.searchData })
        .then(res => {
          this.$Progress.finish()
          this.reportData = res.data
          console.log('reportData =>', this.reportData)
          if (this.searchData.type === 'today') {
            this.chartData.series.push(
              {
                type: 'bar',
                name: 'Wash',
                data: [this.reportData.totalWashAmount]
              },
              {
                type: 'bar',
                name: 'Dry',
                data: [this.reportData.totalDryAmount]
              },
              {
                type: 'bar',
                name: 'Detergent & Softener',
                data: [this.reportData.totalDASAmount]
              },
              {
                type: 'line',
                name: 'Total',
                data: [this.reportData.totalAmount]
              }
            )
            this.chartData.options.colors.push('#0066FF', '#FF6600', '#FF33CC', '#33CC99')
            const label = JSON.parse(JSON.stringify(this.chartData.options))
            this.options = label
            this.options.xaxis.categories = [`${moment().format('YYYY-MM-DD')}`]
          }
        })
        .catch(err => {
          console.log('error @Get charts')
          console.log(err)
          this.$Progress.fail()
          if (err.response) {
            if (err.response.status === 401) {
              webServices.tokenExpire()
            }
          }
          this.$toast.error({
            title: 'ERROR',
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          })
        })
    },
    onSelect(item) {
      this.selectedId = item
      this.searchData.machineId = item.machineId
    }
  },
  mounted() {
    this.api = axios.create({
      baseURL: this.axios.defaults.baseURL
    })

    this.api.defaults.headers.common['Authorization'] = this.api.defaults.headers.common['Authorization']

    // this.getChartsReport();
    this.getMachine()
  }
}
</script>
<style >
.mx-input {
  height: 36px;
}
</style>